
       if (typeof window !== 'undefined') {
         function loadSvg() {
           var body = document.body;
           var svgDom = document.getElementById('__svg__icons__dom__');
           if(!svgDom) {
             svgDom = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
             svgDom.style.position = 'absolute';
             svgDom.style.width = '0';
             svgDom.style.height = '0';
             svgDom.id = '__svg__icons__dom__';
             svgDom.setAttribute('xmlns','http://www.w3.org/2000/svg');
             svgDom.setAttribute('xmlns:link','http://www.w3.org/1999/xlink');
           }
           svgDom.innerHTML = "<symbol fill=\"none\"  viewBox=\"0 0 24 24\" id=\"icon-exclamation-mark-circle\"><path d=\"M12 8v4m0 4h-.01M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Z\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" /></symbol><symbol fill=\"none\"  viewBox=\"0 0 20 21\" id=\"icon-info-circle\"><path d=\"M10 13.833v-3.334m0-3.333h.008m8.325 3.333a8.333 8.333 0 1 1-16.667 0 8.333 8.333 0 0 1 16.667 0Z\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" /></symbol><symbol fill=\"none\"  viewBox=\"0 0 24 24\" id=\"icon-lock\"><path d=\"M17 11V8A5 5 0 0 0 7 8v3m1.8 10h6.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311C20 18.72 20 17.88 20 16.2v-.4c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C17.72 11 16.88 11 15.2 11H8.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C4 13.28 4 14.12 4 15.8v.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C6.28 21 7.12 21 8.8 21Z\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" /></symbol><symbol fill=\"none\"  viewBox=\"0 0 24 24\" id=\"icon-mail\"><path d=\"m2 7 8.165 5.715c.661.463.992.695 1.351.784a2 2 0 0 0 .968 0c.36-.09.69-.32 1.351-.784L22 7M6.8 20h10.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311C22 17.72 22 16.88 22 15.2V8.8c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C19.72 4 18.88 4 17.2 4H6.8c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C2 6.28 2 7.12 2 8.8v6.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C4.28 20 5.12 20 6.8 20Z\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" /></symbol><symbol fill=\"none\"  viewBox=\"0 0 16 16\" id=\"icon-refresh\"><path d=\"M13.999 6.667s-1.337-1.822-2.423-2.908a6 6 0 1 0 1.521 5.908m.902-3v-4m0 4h-4\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" /></symbol>";
           body.insertBefore(svgDom, body.lastChild);
         }
         if(document.readyState === 'loading') {
           document.addEventListener('DOMContentLoaded', loadSvg);
         } else {
           loadSvg()
         }
      }
        
export default {}